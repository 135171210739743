import React from "react"
import Layout from "../components/Layout"

import {
  fluckiger,
  ghidoni,
  schermata,
  bucher,
  givel,
  huessen,
  osio,
  rossi,
  stutz,
  waldburger,
} from "../components/galleryTiles"
import { SEO } from "../components/seo"

// export default function IndexPage() {
const IndexPage = () => {
  return (
    <Layout>
      <div className={"galleryTilesContainer"}>
        <div>{fluckiger()}</div>
        <div>{ghidoni()}</div>
        <div>{schermata()}</div>
        <div>{bucher()}</div>
        <div>{givel()}</div>
        <div>{huessen()}</div>
        <div>{osio()}</div>
        <div>{rossi()}</div>
        <div>{stutz()}</div>
        <div>{waldburger()}</div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <SEO />
// export const Head = () => <title>This should be it</title>
