import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

export const mwLogo = () => {
  return (
    <div>
      <StaticImage src={"../assets/icons/mw_logo.png"} alt="mwLogo" />
    </div>
  )
}

export const fluckiger = () => {
  return (
    <div className="galleryContainer">
      <StaticImage
        src={"../assets/images/Fluckiger/_DSC1980.jpg"}
        alt="fluckiger"
        className="galleryImage"
      />
      <div
        className="galleryOverlay"
        onClick={() => navigate("/projects/fluckiger")}
      >
        <div className="galleryText">Flückiger</div>
      </div>
    </div>
  )
}

export const ghidoni = () => {
  return (
    <div className="galleryContainer">
      <StaticImage
        src={"../assets/images/Balerna Ghidoni/_DSC1920.jpg"}
        alt="Ghidoni"
        className="galleryImage"
      />
      <div
        className="galleryOverlay"
        onClick={() => navigate("/projects/ghidoni")}
      >
        <div className="galleryText">Balerna Ghidoni</div>
      </div>
    </div>
  )
}

export const schermata = () => {
  return (
    <div className="galleryContainer">
      <StaticImage
        src={"../assets/images/Blatter Foehn/_DSC2131.jpg"}
        alt="BlatterFoehn"
        className="galleryImage"
      />
      <div
        className="galleryOverlay"
        onClick={() => navigate("/projects/foehn")}
      >
        <div className="galleryText">Blatter Foehn</div>
      </div>
    </div>
  )
}

export const scultura = () => {
  return (
    <div className="galleryContainer">
      <StaticImage
        src={"../assets/images/scultura.jpeg"}
        alt="Alambicco"
        className="galleryImage"
      />
      <div className="galleryOverlay">
        <div className="galleryText">Scultura</div>
      </div>
    </div>
  )
}

export const bucher = () => {
  return (
    <div className="galleryContainer">
      <StaticImage
        src={"../assets/images/Bucher/_DSC2255.jpg"}
        alt="Bucher"
        className="galleryImage"
      />
      <div
        className="galleryOverlay"
        onClick={() => navigate("/projects/bucher")}
      >
        <div className="galleryText">Bucher</div>
      </div>
    </div>
  )
}

export const givel = () => {
  return (
    <div className="galleryContainer">
      <StaticImage
        src={"../assets/images/Givel/_DSC2175.jpg"}
        alt="Givel"
        className="galleryImage"
      />
      <div
        className="galleryOverlay"
        onClick={() => navigate("/projects/givel")}
      >
        <div className="galleryText">Givel</div>
      </div>
    </div>
  )
}

export const huessen = () => {
  return (
    <div className="galleryContainer">
      <StaticImage
        src={"../assets/images/Huessen/_DSC2534.jpg"}
        alt="Huessen"
        className="galleryImage"
      />
      <div
        className="galleryOverlay"
        onClick={() => navigate("/projects/huessen")}
      >
        <div className="galleryText">Huessen</div>
      </div>
    </div>
  )
}

export const osio = () => {
  return (
    <div className="galleryContainer">
      <StaticImage
        src={"../assets/images/Osio/_DSC2456.jpg"}
        alt="Osio"
        className="galleryImage"
      />
      <div
        className="galleryOverlay"
        onClick={() => navigate("/projects/osio")}
      >
        <div className="galleryText">Osio</div>
      </div>
    </div>
  )
}

export const rossi = () => {
  return (
    <div className="galleryContainer">
      <StaticImage
        src={"../assets/images/Rossi/_DSC2142.jpg"}
        alt="Rossi"
        className="galleryImage"
      />
      <div
        className="galleryOverlay"
        onClick={() => navigate("/projects/rossi")}
      >
        <div className="galleryText">Rossi</div>
      </div>
    </div>
  )
}

export const stutz = () => {
  return (
    <div className="galleryContainer">
      <StaticImage
        src={"../assets/images/Stutz/_DSC2275.jpg"}
        alt="Stutz"
        className="galleryImage"
      />
      <div
        className="galleryOverlay"
        onClick={() => navigate("/projects/stutz")}
      >
        <div className="galleryText">Stutz</div>
      </div>
    </div>
  )
}

export const waldburger = () => {
  return (
    <div className="galleryContainer">
      <StaticImage
        src={"../assets/images/Waldburger/feelgood2020_tic_060.jpg"}
        alt="Waldburger"
        className="galleryImage"
      />
      <div
        className="galleryOverlay"
        onClick={() => navigate("/projects/waldburger")}
      >
        <div className="galleryText">Waldburger</div>
      </div>
    </div>
  )
}
